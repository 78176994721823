export default {
  colors: {
    primary: '#bd93f9',
    background: '#282a36',
    shape: `#50fa7b`,
    title: `#bd93f9`,
    text: `#f8f8f2`,
    components: {
      blockquote: {
        background: `#feebc8`,
        text: `#2d3748`,
      },
    },
  },
};
